define("discourse/plugins/discourse-global-communities/discourse/controllers/program-pillars", ["exports", "@ember/controller", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _service, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PROGRAM_PILLARS_ROUTE_NAME = "program-pillars";
  class ProgramPillarsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "agcProgramPillars", [_service.service]))();
    #agcProgramPillars = (() => (dt7948.i(this, "agcProgramPillars"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("agc-custom:filter-select", this, this._handleFilterSelect);
    }
    onRegionSelect(id) {
      this.router.transitionTo(`/program-pillars/${id}`);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onRegionSelect", [_decorators.bind]))();
    get title() {
      return this.agcProgramPillars.titleFor(this.model.programPillar);
    }
    get subtitle() {
      return this.agcProgramPillars.subtitleFor(this.model.programPillar);
    }
    get regions() {
      const regions = this.site.program_pillars.map(pp => {
        return {
          name: pp.region,
          region: pp.region
        };
      });
      return regions;
    }
    get hasMultipleRegions() {
      return this.regions.length > 1;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("agc-custom:filter-select", this, this._handleFilterSelect);
    }
    get tagOptions() {
      const allOption = {
        id: "all",
        label: _discourseI18n.default.t("program_pillars.all_option_label"),
        count: this.model.programPillar.total_count
      };
      const childTags = this.model.programPillar.child_tags.map(t => {
        return {
          id: t.tag_name,
          label: t.translation_key ? _discourseI18n.default.t(t.translation_key) : t.tag_name,
          count: t.count
        };
      });
      return [allOption, ...childTags];
    }
    get defaultTagOption() {
      return this.t || this.tagOptions[0].id;
    }
    _handleFilterSelect(_ref) {
      let {
        scope,
        selected
      } = _ref;
      if (scope !== PROGRAM_PILLARS_ROUTE_NAME) {
        return;
      }
      selected === this.defaultTagOption || selected === this.get("t") ? this.set("t", "") : this.set("t", selected);
      this.agcProgramPillars.loadMorePressed = false;
    }
    get hasHero() {
      return !!this.model.programPillar.hero_upload;
    }
    get hasWidgets() {
      return this.model.programPillar.sideWidgets.length;
    }
  }
  _exports.default = ProgramPillarsController;
});